<template>
  <!-- 菜单管理 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="btn-group">
      <div>
        <el-button v-checkbtn="isShowBtn(AUTH_BTN.menu_list_add)" type="primary" plain size="mini" icon="el-icon-plus" @click="$router.push({ name: 'MenuAdd' })"
          >新增</el-button
        >
      </div>
    </div>
    <el-table
      :data="list"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :header-row-style="{ height: '50px', color: '#626e7c' }"
      :cell-style="changeTr"
      :header-cell-style="changeHeader"
    >
      <el-table-column label="名称" align="left" width="250px">
        <template slot-scope="scope">{{ scope.row.menu_name }}</template>
      </el-table-column>
      <el-table-column label="菜单类型" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.menu_type == 1" type="success">目录</el-tag>
          <el-tag v-if="scope.row.menu_type == 2" type="info">菜单</el-tag>
          <el-tag v-if="scope.row.menu_type == 3" type="warning">按钮</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link v-checkbtn="isShowBtn(AUTH_BTN.menu_list_add)" :underline="false" type="primary" @click="goAdd(scope.row)">新增</el-link>
          <el-link v-checkbtn="isShowBtn(AUTH_BTN.menu_list_edit)" :underline="false" style="margin: 0 10px" type="primary" @click="goEdit(scope.row)">编辑</el-link>

          <el-link v-checkbtn="isShowBtn(AUTH_BTN.menu_list_del)" @click="apiDelMenu(scope.row.id)" :underline="false" type="danger"> 删除 </el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getMenusTree, delMenu } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      list: [],
      total: 0
    }
  },

  mounted() {},
  activated() {
    // this.apiQueryMenuTree()
  },
  methods: {
    // 新增子菜单
    goAdd(row) {
      const { id, pid } = row
      this.$router.push({
        name: 'MenuAdd',
        params: {
          menuId: id,
          parent_id: pid
        }
      })
    },
    goEdit(row) {
      const { id } = row
      this.$router.push({ name: 'MenuEdit', params: { menuId: id } })
    },
    async apiQueryMenuTree() {
      let res = await getMenusTree()
      this.list = res || []
    },

    async apiDelMenu(id) {
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delMenu(id).then(() => {
            this.apiQueryMenuTree()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 修改第一列无children的样式
    changeTr({ row, column, rowIndex, columnIndex }) {
      if (!row.children && columnIndex == 0) {
        return { paddingLeft: '100px' }
      }
      if (columnIndex == 0) {
        return { paddingLeft: '100px' }
      }
    },
    changeHeader({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return { background: '#f8f9fb', padding: '8px 0 0 130px' }
      } else {
        return { background: '#f8f9fb', padding: '8px 0' }
      }
    },
    handleClick(val) {
      this.apiQueryMenuTree()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
