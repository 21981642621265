import { http } from '@/http/axios.js'

// 菜单列表
export function getMenusList(params) {
  return http({
    url: '/company/basic/rdbcMenu/list',
    method: 'GET',
    params
  })
}

// 菜单树
export function getMenusTree(params) {
  return http({
    url: '/company/basic/rdbcMenu/tree',
    method: 'GET',
    params
  })
}
export function getMenuDetail(params) {
  return http({
    url: `/company/basic/rdbcMenu/detail`,
    method: 'GET',
    params
  })
}

export function editMenu(data) {
  return http({
    url: `/company/basic/rdbcMenu/edit`,
    method: 'post',
    data
  })
}

export function addMenu(data) {
  return http({
    url: `/company/basic/rdbcMenu/add`,
    method: 'POST',
    data
  })
}

export function delMenu(id) {
  return http({
    url: `/company/basic/rdbcMenu/delete`,
    method: 'post',
    data: { id }
  })
}

// 模块列表
export function getModuleList() {
  return http({
    url: `/auth/menu/module/list`,
    method: 'get'
  })
}
